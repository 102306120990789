<template>
  <div>
    <div class="card-body" :class="{'was-validated': $v.value.$dirty}">
      <template v-if="edit">
        <div class="form-group row d-flex">
          <label class="col-sm-3 col-form-label text-right font-weight-bold">Status <span class="text-danger">*</span></label>
          <div class="col-sm-7">
            <select
              v-model="$v.value.status.$model"
              class="form-control custom-select"
              :class="{'is-invalid': $v.value.status.$error}"
              :disabled="articleCreateMode"
            >
              <option value="">Select status</option>
              <option
                v-for="(status, key) in statuses"
                :key="key"
                :value="key">
                {{status.name}}
              </option>
            </select>
            <template v-if="$v.value.status.$dirty">
              <div v-if="!$v.value.status.required" class="invalid-feedback d-block">Status is required</div>
            </template>
          </div>
        </div>
        <div class="form-group row d-flex">
          <label class="col-sm-3 col-form-label text-right font-weight-bold">Rank <span class="text-danger">*</span></label>
          <div class="col-sm-7">
            <select
              v-model="$v.value.rank.$model"
              class="form-control custom-select"
              :class="{'is-invalid': $v.value.rank.$error}"
              :disabled="value.status !== 'active'"
            >
              <option value="">Select rank</option>
              <option value="primary">Primary</option>
              <option value="secondary">Secondary</option>
            </select>
            <template v-if="$v.value.rank.$dirty">
              <div v-if="!$v.value.rank.required" class="invalid-feedback d-block">Rank is required</div>
            </template>
          </div>
        </div>
        <div
          class="form-group row d-flex"
        >
          <label class="col-sm-3 col-form-label text-right font-weight-bold">Import <span class="text-danger">*</span></label>
          <label class="col-sm-7 custom-switch">
            <input
              v-model="value.is_import"
              :disabled="(value.material_type && !value.material_type.is_purchasable) || !articleCreateMode"
              type="checkbox"
              class="custom-switch-input"
            >
            <span class="custom-switch-indicator"></span>
          </label>
        </div>
        <div v-if="value.is_import" class="form-group row d-flex">
          <label class="col-sm-3 col-form-label text-right font-weight-bold">Country of Origin <span class="text-danger">*</span></label>
          <div class="col-sm-7">
            <v-select
              v-model="selectedCountries"
              :disabled="!value.material_type.is_purchasable"
              :options="countries"
              :selectable="option => !$v.value.countries.$model.map(country => country.id).includes(option.id) && option.code !== UAE_ISO_CODE"
              :required="!$v.value.countries.$model.length"
              :class="{'is-invalid': $v.value.brand.$error}"
              taggable
              multiple>
              <template slot="option" slot-scope="option">
                <div class="d-center">
                  {{option.label}}
                </div>
              </template>
              <template slot="selected-option" slot-scope="option">
                <div class="selected d-center">
                  {{option.label}}
                </div>
              </template>
            </v-select>
            <template v-if="$v.value.countries.$dirty">
              <div v-if="!$v.value.countries.required" class="invalid-feedback d-block">Countries is required</div>
            </template>
          </div>
        </div>
        <div class="form-group row d-flex">
          <label class="col-sm-3 col-form-label text-right font-weight-bold">Brand <span class="text-danger">*</span></label>
          <div class="col-sm-7">
            <autocomplete
              :list.sync="brands"
              :nextPage="nextBrands"
              placeholder="Select brand"
              :class="{'is-invalid': $v.value.brand.$error}"
              :value="value.brand"
              label="name"
              entity="brand"
              disabled
              @input="value => updateBrand(value)"></autocomplete>
            <template v-if="$v.value.brand.$dirty">
              <div v-if="!$v.value.brand.required" class="invalid-feedback d-block">Brand is required</div>
            </template>
          </div>
        </div>

        <div class="form-group row d-flex">
          <label class="col-sm-3 col-form-label text-right font-weight-bold">Net weight <i v-b-tooltip="NET_WEIGHT" class="fe fe-info"></i> <span class="text-danger">*</span></label>
          <div class="col-sm-7">
            <div class="row">
              <div class="col-3">
                <input
                  v-model="$v.value.net_weight.$model"
                  type="number"
                  step="any"
                  class="form-control"
                  :class="{'is-invalid': $v.value.net_weight.$error}"
                  placeholder="Enter weight"
                  @change="updateArticleData"
                >
              </div>
              <div class="col-3 pl-0">
                <select
                  v-model="$v.value.net_weight_uom.$model"
                  class="form-control custom-select"
                  :class="{'is-invalid': $v.value.net_weight_uom.$error}"
                  @change="$emit('update-names')">
                  <option :value="null">Select unit</option>
                  <option
                    v-for="unit in uomNetWeights"
                    :key="unit.id"
                    :value="unit">
                    {{unit.name}}
                  </option>
                </select>
              </div>
            </div>
            <template v-if="$v.value.net_weight.$dirty">
              <div v-if="!$v.value.net_weight.required" class="invalid-feedback d-block">Net weight is required</div>
            </template>
            <template v-if="$v.value.net_weight_uom.$dirty">
              <div v-if="!$v.value.net_weight_uom.required" class="invalid-feedback d-block">Net weight unit of measure is required</div>
            </template>
          </div>
        </div>
        <div class="form-group row d-flex">
          <label class="col-sm-3 col-form-label text-right font-weight-bold">Quantity per net weight <i v-b-tooltip="QUANTITY_PER_NET_WEIGHT" class="fe fe-info"></i> <span class="text-danger">*</span></label>
          <div class="col-sm-7">
            <div class="row">
              <div class="col-3">
                <input
                  v-model="$v.value.qty_per_net_weight.$model"
                  type="number"
                  step="any"
                  class="form-control"
                  :class="{'is-invalid': $v.value.qty_per_net_weight.$error}"
                  placeholder="Enter weight">
              </div>
              <div class="col-3 pl-0">
                <select
                  v-model="$v.value.qty_per_net_weight_uom.$model"
                  class="form-control custom-select"
                  :class="{'is-invalid': $v.value.qty_per_net_weight_uom.$error}">
                  <option :value="null">Select unit</option>
                  <option
                    v-for="unit in uomBaseWeights"
                    :key="unit.id"
                    :value="unit">
                    {{unit.name}}
                  </option>
                </select>
              </div>
            </div>
            <template v-if="$v.value.qty_per_net_weight.$dirty && $v.value.qty_per_net_weight_uom.required">
              <div v-if="!$v.value.qty_per_net_weight.required" class="invalid-feedback d-block">Quantity per net weight is required</div>
            </template>
            <template v-if="$v.value.qty_per_net_weight_uom.$dirty && $v.value.qty_per_net_weight.required">
              <div v-if="!$v.value.qty_per_net_weight_uom.required" class="invalid-feedback d-block">Quantity per net weight unit of measure is required</div>
            </template>
            <template v-if="$v.value.qty_per_net_weight.$dirty || $v.value.qty_per_net_weight_uom.$dirty">
              <div v-if="!$v.value.qty_per_net_weight.required && !$v.value.qty_per_net_weight_uom.required" class="invalid-feedback d-block">Quantity per net weight and unit of measure is required</div>
            </template>
          </div>
        </div>

        <!-- Base unit -->
        <div class="form-group row d-flex">
          <label class="col-sm-3 col-form-label text-right font-weight-bold">Base unit <i v-b-tooltip="BASE_UNIT" class="fe fe-info"></i> <span class="text-danger">*</span></label>
          <div class="col-sm-7">
            <div class="row">
              <div class="col-3">
                <input
                  v-model="$v.value.base_unit.$model"
                  type="number"
                  step="any"
                  class="form-control"
                  :class="{'is-invalid': $v.value.base_unit.$error}"
                  placeholder="Enter weight"
                  @change="updateArticleData"
                >
              </div>
              <div class="col-3 pl-0">
                <select
                  v-model="$v.value.base_unit_uom.$model"
                  class="form-control custom-select"
                  :class="{'is-invalid': $v.value.base_unit_uom.$error}"
                  @change="$emit('update-names')">
                  <option :value="null">Select unit</option>
                  <option
                    v-for="unit in baseUom"
                    :key="unit.id"
                    :value="unit">
                    {{unit.name}}
                  </option>
                </select>
              </div>
            </div>
            <template v-if="$v.value.base_unit.$dirty">
              <div v-if="!$v.value.base_unit.required" class="invalid-feedback d-block">Base unit is required</div>
            </template>
            <template v-if="$v.value.base_unit_uom.$dirty">
              <div v-if="!$v.value.base_unit_uom.required" class="invalid-feedback d-block">Base unit of measure is required</div>
            </template>
          </div>
        </div>

        <div
          v-if="showCheckbox"
          class="form-group row d-flex"
        >
          <label class="col-sm-3 col-form-label text-right font-weight-bold">Ignoring quantity in price calculations <i v-b-tooltip="IGNORE_QUANTITY_IN_PRICE_CALCULATION" class="fe fe-info"></i></label>
          <label class="col-sm-7 custom-switch">
            <input
              v-model="$v.value.ignore_qty_in_price_calc.$model"
              type="checkbox"
              class="custom-switch-input"
            >
            <span class="custom-switch-indicator"></span>
          </label>
        </div>
        <div class="form-group row d-flex">
          <label class="col-sm-3 col-form-label text-right font-weight-bold">Article name <span class="text-danger">*</span></label>
          <div class="col-sm-7">
            <input
              v-model="$v.value.name.$model"
              type="text"
              class="form-control"
              :class="{'is-invalid': $v.value.name.$error}"
              :disabled="!articleCreateMode && !isPackable && !isSubRecipe"
            />
            <template v-if="$v.value.name.$dirty">
              <div v-if="!$v.value.name.required" class="invalid-feedback d-block">Article name is required</div>
              <div v-if="!$v.value.name.apiError" class="invalid-feedback d-block">{{errors.name.join(' ')}}</div>
            </template>
          </div>
        </div>
        <div class="form-group row d-flex">
          <label class="col-sm-3 col-form-label text-right font-weight-bold">Assignment type</label>
          <div class="col-sm-7">
            <select
              v-model="value.assign_bin_type"
              class="form-control custom-select">
              <option
                v-for="sType in ASSIGNMENT_TYPES"
                :key="sType"
                :value="sType">
                {{capitalize(lowerCase(sType))}}
              </option>
            </select>
          </div>
        </div>
        <div class="form-group row d-flex">
          <label class="col-sm-3 col-form-label text-right font-weight-bold">
            Internal waste rule
            <i v-b-tooltip="INTERNAL_WASTE_RULE" class="fe fe-info"></i> <span class="text-danger">*</span>
          </label>
          <div class="col-sm-7">
            <select
              v-model="$v.value.internal_waste_rule.$model"
              class="form-control custom-select"
              :class="{'is-invalid': $v.value.internal_waste_rule.$error}">
              <option value="">Select internal waste rule</option>
              <option
                v-for="rule in WASTAGE_RULES"
                :key="rule"
                :value="rule">
                {{capitalize(lowerCase(rule))}}
              </option>
            </select>
            <template v-if="$v.value.internal_waste_rule.$dirty">
              <div v-if="!$v.value.internal_waste_rule.required" class="invalid-feedback d-block">Internal waste rule is required</div>
            </template>
          </div>
        </div>
        <div class="form-group row d-flex">
          <label class="col-sm-3 col-form-label text-right font-weight-bold">
            External waste rule
            <i v-b-tooltip="EXTERNAL_WASTE_RULE" class="fe fe-info"></i> <span class="text-danger">*</span>
          </label>
          <div class="col-sm-7">
            <select
              v-model="$v.value.external_waste_rule.$model"
              class="form-control custom-select"
              :class="{'is-invalid': $v.value.external_waste_rule.$error}">
              <option value="">Select external waste rule</option>
              <option
                v-for="rule in WASTAGE_RULES"
                :key="rule"
                :value="rule">
                {{capitalize(lowerCase(rule))}}
              </option>
            </select>
            <template v-if="$v.value.external_waste_rule.$dirty">
              <div v-if="!$v.value.external_waste_rule.required" class="invalid-feedback d-block">External waste rule is required</div>
            </template>
          </div>
        </div>
      </template>
      <template v-else>
        <form-description label="Status" :value="statuses[value.status].name"/>
        <form-description label="Rank" :value="value.rank || 'NA'"/>
        <form-description label="Import" :value="value.is_import ? 'Imported' : 'Local'"/>
        <form-description label="Country of origin" :value="value.is_import ? countryNames.join(', ') : 'Local'"/>
        <form-description label="Brand" :value="(value.brand || {}).name"/>
        <form-description>
          <label class="text-right font-weight-bold col-sm-5">Net weight <i v-b-tooltip="BASE_UNIT" class="fe fe-info"></i> :</label>
          <div class="col-sm-7">
            <template v-if="value.net_weight">
              {{`${value.net_weight } ${(value.net_weight_uom || {}).name}`}}
            </template>
            <template v-else> NA </template>
          </div>
        </form-description>
        <form-description>
          <label class="text-right font-weight-bold col-sm-5">Quantity per net weight <i v-b-tooltip="BASE_UNIT" class="fe fe-info"></i> :</label>
          <div class="col-sm-7">
            <template v-if="value.qty_per_net_weight">
              {{`${value.qty_per_net_weight } ${(value.qty_per_net_weight_uom || {}).name}`}}
            </template>
            <template v-else> NA </template>
          </div>
        </form-description>
        <form-description>
          <label class="text-right font-weight-bold col-sm-5">Base unit <i v-b-tooltip="BASE_UNIT" class="fe fe-info"></i> :</label>
          <div class="col-sm-7">
            <template v-if="value.base_unit">
              {{`${value.base_unit } ${(value.base_unit_uom || {}).name}`}}
            </template>
            <template v-else> NA </template>
          </div>
        </form-description>
        <form-description v-if="showCheckbox">
          <label class="text-right font-weight-bold col-sm-5">Ignoring quantity in price calculations <i v-b-tooltip="IGNORE_QUANTITY_IN_PRICE_CALCULATION" class="fe fe-info"></i> :</label>
          <div class="col-sm-7">{{`${!!value.ignore_qty_in_price_calc ? 'Yes' : 'No'}`}}</div>
        </form-description>

        <form-description label="Article name" :value="value.name || 'NA'"/>
        <form-description label="Assignment type" :value="capitalize(lowerCase(value.assign_bin_type))"/>
        <form-description>
          <label class="text-right font-weight-bold col-sm-5">
            Internal waste rule <i v-b-tooltip="INTERNAL_WASTE_RULE" class="fe fe-info"></i> :
          </label>
          <div class="col-sm-7">
            {{capitalize(lowerCase(value.internal_waste_rule)) || 'NA'}}
          </div>
        </form-description>
        <form-description>
          <label class="text-right font-weight-bold col-sm-5">
            External waste rule <i v-b-tooltip="EXTERNAL_WASTE_RULE" class="fe fe-info"></i> :
          </label>
          <div class="col-sm-7">
            {{capitalize(lowerCase(value.external_waste_rule)) || 'NA'}}
          </div>
        </form-description>
      </template>
    </div>
    <div v-if="edit" class="card-footer d-flex">
      <button
        class="btn btn-primary ml-auto"
        data-test="btn-general-apply"
        @click.prevent="validate">
        Apply
      </button>
    </div>

    <b-modal
      v-model="showDeleteModal"
      size="md"
      title="Warning! Permanent deletion"
      hide-footer>
      <template slot="modal-header-close">
        <wbr/>
      </template>
      <p>Are you sure you want to permanently discontinue this article?</p>
      <p>This will permanently remove the article from the system!</p>
      <div class="d-flex justify-content-end pt-6">
        <button
          class="btn btn-danger mr-2"
          data-test="btn-confirm-deletion"
          @click.prevent="confirmDeletion">
          Confirm
        </button>
        <button
          class="btn btn-primary"
          data-test="btn-cancel-deletion"
          @click.prevent="closeDeleteModal">
          Cancel
        </button>
      </div>
    </b-modal>

    <rank-swap-modal
      v-if="showRankChangeModal"
      :show="showRankChangeModal"
      :master-recipe-count="usedByMasterRecipesCount"
      @show="toggleRankChangeModal"
    />
  </div>
</template>

<script>

import {brands, countries, articles} from '@/services';
import Autocomplete from '@/components/Autocomplete';
import ArticleStatus from '@/assets/enums/ArticleStatus';
import FormDescription from '@/components/FormDescription';
import {required, requiredIf} from 'vuelidate/lib/validators';
import RankSwapModal from '@/views/articles/modals/RankSwapModal';

const UAE_ISO_CODE = 'ARE';
const BASE_UNIT = 'This refers to the net weight of one unit as purchased from supplier.';
const NET_WEIGHT = 'This refers to the net weight of one unit as purchased from supplier.';
const QUANTITY_PER_NET_WEIGHT = 'This refers to how the article will be measured in recipes.';
const IGNORE_QUANTITY_IN_PRICE_CALCULATION = 'This flag is used to indicate you will not be using net weight to determine the cost of the article, you will use quantity per net weight instead. This should be marked as No for articles measured in recipe by quantity.';
const ASSIGNMENT_TYPES = ['default', 'flow-through'];
const WASTAGE_RULES = ['return-to-inventory', 'write-off', 'donation'];
const INTERNAL_WASTE_RULE = 'How to handle waste from a box that was cancelled and set aside during production and never left the facility';
const EXTERNAL_WASTE_RULE = 'How to handle waste from a box that was cancelled while it had already left the facility';

export default {
  components: {

    RankSwapModal,
    Autocomplete,
    FormDescription,
  },
  props: {
    edit: {
      default: false,
      type: Boolean,
    },
    errors: {
      default: () => {},
      type: Object,
    },
    isNew: {
      require: true,
      type: Boolean,
    },
    old: {
      default: () => {},
      type: Object,
    },
    uom: {
      required: true,
      type: Array,
    },
    value: {
      required: true,
      type: Object,
    },
    showCheckbox: {
      type: Boolean,
      default: false,
    },
    articleCreateMode: {
      type: Boolean,
      default: false,
    },
    isPackable: {
      type: Boolean,
      default: false,
    },
    isSubRecipe: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      UAE_ISO_CODE,
      BASE_UNIT,
      NET_WEIGHT,
      QUANTITY_PER_NET_WEIGHT,
      IGNORE_QUANTITY_IN_PRICE_CALCULATION,
      ASSIGNMENT_TYPES,
      WASTAGE_RULES,
      brands: [],
      countries: [],
      showDeleteModal: false,
      showRankChangeModal: false,
      usedByMasterRecipesCount: 0,
      INTERNAL_WASTE_RULE,
      EXTERNAL_WASTE_RULE,
    };
  },
  computed: {
    statuses() {
      return ArticleStatus;
    },
    baseUom() {
      if (this.value.ingredient.measured_in_recipe_by === 'quantity') {
        return this.uom.filter(value => value.is_for_article_qty_per_net_weight);
      }
      return this.uom.filter(value => value.is_for_article_net_weight);
    },
    uomNetWeights() {
      return this.uom.filter(value => value.is_for_article_net_weight);
    },
    uomBaseWeights() {
      return this.uom.filter(value => value.is_for_article_qty_per_net_weight);
    },
    countryNames() {
      return this.value.countries.map(country => country?.name);
    },
    selectedCountries: {
      get() {
        return this.$v.value.countries.$model.map(country => ({
          id: country.id,
          code: country.iso_code || country.code,
          label: country.name,
        }));
      },
      set(value) {
        const setNewValues = value.map(country => ({
          id: country.id,
          iso_code: country.iso_code,
          name: country.label,
        }));

        this.$v.value.countries.$model = setNewValues;
      },
    },
  },
  watch: {
    'value.status'(value) {
      if (value !== 'active') {
        this.value.rank = 'secondary';
        alert('Article rank will switch to Secondary, only Active articles can be Primary');
      }
    },
    'value.rank'(newValue, oldValue) {
      if (oldValue !== '' && newValue === 'secondary') {
        // when article rank is changed to secondary
        // we need to check if this article is used in any master recipes
        // to prevent editing this article in case it's present in a recipe
        this.checkUsedInRecipes();
      }
    },
  },
  created() {
    brands.getByParameters({column: 'name', direction: 'asc'}).then(result => this.brands = result);
    countries.getByParameters({column: 'name', direction: 'asc'}).then(result => {
      this.countries = result.map(country => ({
        id: country.id,
        label: country.name,
        code: country.iso_code,
        name: country.name,
      }));

      this.$v.value.countries.$model = this.$v.value.countries.$model.filter(item => item).map(item => {
        if (Number.isInteger(item)) {
          return this.countries.find(country => country.id === item);
        }
        return item;
      });
    });

    if (this.articleCreateMode) {
      this.value.status = 'active';
    }
  },
  methods: {
    toggleRankChangeModal(value) {
      this.showRankChangeModal = value;
    },
    async checkUsedInRecipes() {
      const results = await articles.getUsedByRecipes(this.value.id);

      // this article's ingredient is found in n master recipes
      if (results?.master_recipes > 0) {
        this.usedByMasterRecipesCount = results.master_recipes;
        return this.toggleRankChangeModal(true);
      }
    },
    async nextBrands(page, query) {
      const params = {

        column: 'name',
        direction: 'asc',
        page,
      };

      if (query.length) params.query = query;

      const result = await brands.getByParameters(params);

      return result;
    },
    updateArticleData() {
      this.$emit('update-names');

      // update replenishments net weight
      this.value.replenishments.forEach(replenishment => {
        replenishment.net_weight = replenishment.pack_size_per_oum * this.value.base_unit;
      });
    },
    updateBrand(value) {
      this.value.brand = value;
      this.$emit('update-names');
    },
    closeDeleteModal() {
      this.showDeleteModal = false;
    },
    async confirmDeletion() {
      await this.validate();
      this.closeDeleteModal();
    },
    validate() {
      this.$v.value.$touch();

      if (!this.$v.value.$invalid) {
        if (this.value.status === 'permanently_discontinued' && !this.showDeleteModal) {
          this.showDeleteModal = true;
          return;
        }

        this.$emit('valid');
      }
    },
  },
  validations: {
    value: {

      brand: {

        required,
      },
      name: {

        apiError: function(value) {
          return !(this.errors?.name && this.old?.name === value);
        },
        required,
      },
      base_unit: {

        required,
      },
      base_unit_uom: {

        required,
      },
      net_weight: {

        required,
      },
      net_weight_uom: {

        required,
      },
      qty_per_net_weight: {

        required,
      },
      qty_per_net_weight_uom: {

        required,
      },
      status: {
        required,
      },
      rank: {

        required,
      },
      ignore_qty_in_price_calc: {
        required: false,
      },
      countries: {
        required: requiredIf(function() {
          return this.value.is_import && this.value.material_type?.is_purchasable;
        }),
      },
      internal_waste_rule: {
        required,
      },
      external_waste_rule: {
        required,
      },
    },
  },
};

</script>
